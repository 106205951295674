<template>
    <!-- 物流行业 -->
    <div>
        <el-row>
            <el-col :span="24">
                <img class="medical-bg" src="../../img/solution/logisticsSolution/logistics_bg.png">
            </el-col>
            <el-col :span="24" class="solution-title">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">物流行业</el-col>
                <el-col :span="17" class="center-el-col"><div class="solution-line"></div></el-col>
                <el-col :span="14" class="center-el-col">
                    <p class="text-center" style="color: #666666;">某物流公司成立于2006年，是经过深圳市政府特许、市运输局批准、市工商局正规注册的国营大型物流公司！注册资本资金1000万，公司主要经营：国内公路运输、铁路运输、航空运输、中港运输物流、吨车往返运输等业务，公司对于业务往来单据和客户资料等的集中管理和存储要求严格。</p>
                </el-col>
                <el-col :span="14" class="center-el-col">
                    <div class="title-end">
                        <div class="solution-line2"></div>
                        <div>客户背景</div>
                    </div>
                </el-col>
            </el-col>
            <el-col :span="24" class="solution-pain">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">行业痛点</el-col>
                <div class="pain-cards">
                    <div class="pain-card">
                        <div><img src="../../img/solution/logisticsSolution/icon1.png"></div>
                        <div class="text-center">文件缺乏统一的存储、管理</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">物流公司普遍存在“小、散”，文件缺乏统一的存储和管理，无法实时调用与处理，影响企业的办公效率。</div>
                    </div>
                    <div class="pain-card">
                        <div><img src="../../img/solution/logisticsSolution/icon2.png"></div>
                        <div class="text-center">跨地域协作不方便</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">跨地域分公司协作时，文件安全难以保障，也无法做到实时信息沟通，易出现信息滞后。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/logisticsSolution/icon3.png"></div>
                        <div class="text-center">纸质数据电子化管理困难</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">采集、仓储、运输等众多环节中依然使用大量纸质单据，难以存储和管理，浪费大量人力和财力。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/logisticsSolution/icon4.png"></div>
                        <div class="text-center">资料查找慢且效率效</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">企业货物、运输、客户等多种信息缺乏有效的分类管理，文件查找速度缓慢，无法及时得到所需信息，影响企业服务水平。</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="24">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">伊登云解决方案</el-col>
                <div class="eden-solution-plate">
                    <p class="eden-solution-title">>>建立文档集中存储平台</p>
                    <p class="eden-solution-content">物流公司普遍存在“小、散”，文件缺乏统一的存储和管理，无法实时调用与处理，影响企业的办公效率。</p>

                    <p class="eden-solution-title">>>多平台跨区域办公</p>
                    <p class="eden-solution-content">提供浏览器、客户端、移动端等多种访问方式，对登录人员进行全面的安全管理，打破地域空间限制，满足跨部门、跨地域的办公需求。</p>

                    <p class="eden-solution-title">>>全文检索以及误删回复</p>
                    <p class="eden-solution-content">支持文本类文件的全文检索，并且支持用户对文件和文件夹进线自定义标签，该标签同样支持全文检索，让用户更高效的查找文件。</p>

                    <p class="eden-solution-title">>>纸质单据电子化</p>
                    <p class="eden-solution-content">无缝集成各种扫描仪与企业已有IT系统，将各种纸质单据电子化，根据需求智能分类与管理，实现电子信息管理，提高管理效率。</p>

                    <p class="eden-solution-title">>>文件搜索引擎检索快捷</p>
                    <p class="eden-solution-content">可根据文件名称、类型、内容、格式、修改时间、作者、分类属性等进行搜索，支持全文搜索，加快文件检索速度，提升办公效率。</p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "logisticsSolution",
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
    .medical-bg {
        width: 100%;
    }

    .center-el-col {
        margin: auto;
        float: unset;
    }

    .solution-line {
        border: 1px solid #E5E5E5;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .solution-line2 {
        border: 1px solid #666666;
        width: 2rem;
        margin-right: 1rem;
        margin-top: 0.7rem;
        height: 0px;
    }

    .solution-blue-line {
        width: 48px;
        height: 6px;
        background: #2974E4;
    }

    .medicalSolution {
        margin: auto;
        float: unset;
        font-size: 40px;
        font-weight: 400;
        line-height: 45px;
        color: #3764D7;
        text-align: center;
        width: auto;
    }

    .solution-title {
        padding: 3rem 0px 1.5rem 0px;
    }

    .title-end {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .solution-pain {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }

    .pain-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pain-cards {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.5rem;
        width: 100%;
        max-width: 1110px;
        margin: 2rem auto;
    }

    .pain-card {
        max-width: 33rem;
        text-align: center;
        padding: 0.75rem;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        //border: 1px solid #dee2e6;
    }

    .pain-card1 {
        border-bottom: 0px;
    }

    .pain-content {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
    }

    .eden-solution-plate {
        margin: 2rem 0rem;
        max-width: 1200px;
        width: 100%;
        margin: auto;
    }

    .eden-solution-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold
    }

    .eden-solution-content {
        color: #333333;
        font-size: 14px;
    }

    * {
        font-size: 16px
    }

    @media screen and (min-width: 1200px){
        .pain-cards .pain-card:nth-child(2n) {
            border-right: 0px;
        }
    }

    @media screen and (max-width: 1200px){
        .pain-cards {
            max-width: 400px;
        }

        .eden-solution-plate {
            max-width: 800px;
        }

        .pain-card {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(3) {
            border-bottom: 1px solid #dee2e6;
        }
    }

    @media screen and (max-width: 800px){
        .pain-cards {
            max-width: 400px;
        }

        .eden-solution-plate {
            max-width: 400px;
        }

        .pain-card {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(3) {
            border-bottom: 1px solid #dee2e6;
        }
    }
</style>